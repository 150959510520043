
let layout = {
    initModallayout: () => {
        layout = new Vue({

            el: '#moduleChange',
            data: {
            },
            mounted: function () {
                this.changeModule();

            },
            methods: {
               
                changeModule: function changeModule () {
                 //   console.log('checkModuleChoice');
                    return this.$http.post(`/api/module/check-choice`, {})
                        .then(response => {
                            if (response.body.module!=null) {
                         //       console.log(response.body.module);
                                this.module=response.body.module;
                                $('#moduleName').text(this.module.name);

                            }
                        })
                        .catch(App.handlers.httpError(() => {  }));
                },
              
                },
            })
        
    }

}



layout.initModallayout();

window.layout = layout;



